dv<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="breadcumbs"> </v-breadcrumbs>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.href"
            class="text-subtitle-2 crumb-item"
            :disabled="item.disabled"
            exact
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="rounded-xl p-2">
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="deep-purple accent-4"
          ></v-progress-linear>
          <v-container fluid>
            <v-row>
              <v-col cols="12" xs="12" sm="2" md="2">
                <v-text-field
                  v-model="foundBy"
                  :label="$t('labels.foundBy')"
                  single-line
                  @keyup.enter="search"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2">
                <v-text-field
                  v-model="venue"
                  :label="$t('labels.venue')"
                  single-line
                  @keyup.enter="search"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2">
                <v-menu
                  ref="modal"
                  v-model="modal"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      v-model="date_from"
                      :label="$t('labels.dateFrom')"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-0 pa-0 border-12"
                      hide-details="true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_from"
                    no-title
                    @input="modal = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2">
                <v-menu
                  ref="modal_to"
                  v-model="modal_to"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      v-model="date_to"
                      :label="$t('labels.dateTo')"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-0 pa-0 border-12"
                      hide-details="true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_to"
                    no-title
                    @input="modal_to = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2">
                <v-autocomplete
                  solo
                  clearable
                  v-model="status"
                  :items="statuses"
                  item-text="status"
                  :label="$t('labels.status')"
                  class="ma-0 pa-0 border-12"
                  hide-details="true"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2">
                <v-btn
                  class="mr-2 border-12"
                  color="error"
                  elevation="2"
                  large
                  @click="search()"
                >
                  {{ $t("buttons.search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" style="padding-bottom: 100px">
        <v-card
          v-show="display == 'none' ? false : true"
          class="rounded-xl p-2"
        >
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-card-title
                  >{{ $t("labels.Result") }}
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-2"
                    color="indigo"
                    fab
                    dark
                    elevation="2"
                    large
                    @click="openDialog()"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="data"
                  :options.sync="options"
                  :loading="loading"
                  :loading-text="$t('messages.loadingMessage')"
                  :server-items-length="totalItems"
                  :page.sync="pagination"
                  page-count="10"
                  class="elevation-1"
                >
                  <template v-slot:[`header.no_doc`]="{ header }">
                    <button @click="sortByFunc(header.value)">
                      {{ header.text }}
                    </button>
                    <i
                      v-if="sortBy === 'desc'"
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-up
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                    <i
                      v-else
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-down
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                  </template>
                  <template v-slot:[`header.found_by`]="{ header }">
                    <button @click="sortByFunc(header.value)">
                      {{ header.text }}
                    </button>
                    <i
                      v-if="sortBy === 'desc'"
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-up
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                    <i
                      v-else
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-down
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                  </template>
                  <template v-slot:[`header.date_found`]="{ header }">
                    <button @click="sortByFunc(header.value)">
                      {{ header.text }}
                    </button>
                    <i
                      v-if="sortBy === 'desc'"
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-up
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                    <i
                      v-else
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-down
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                  </template>
                  <template v-slot:[`header.venue`]="{ header }">
                    <button @click="sortByFunc(header.value)">
                      {{ header.text }}
                    </button>
                    <i
                      v-if="sortBy === 'desc'"
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-up
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                    <i
                      v-else
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-down
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                  </template>
                  <template v-slot:[`header.taken_by`]="{ header }">
                    <button @click="sortByFunc(header.value)">
                      {{ header.text }}
                    </button>
                    <i
                      v-if="sortBy === 'desc'"
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-up
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                    <i
                      v-else
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-down
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                  </template>
                  <template v-slot:[`header.taken_date`]="{ header }">
                    <button @click="sortByFunc(header.value)">
                      {{ header.text }}
                    </button>
                    <i
                      v-if="sortBy === 'desc'"
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-up
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                    <i
                      v-else
                      aria-hidden="true"
                      @click="sortByFunc(header.value)"
                      class="
                        v-icon
                        notranslate
                        v-data-table-header__icon
                        mdi mdi-arrow-down
                        theme--light
                      "
                      style="font-size: 18px"
                    ></i>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editItem(item)"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{ $t("buttons.show") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <!-- Todo add dialog and etc -->
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t("labels.lostAndFound") }}</v-toolbar-title>
              <v-progress-linear
                :active="loadingDialog"
                :indeterminate="loadingDialog"
                absolute
                bottom
                color="deep-purple accent-4"
              ></v-progress-linear>
            </v-toolbar>
          </v-card>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-text-field
                  v-model="foundByDialog"
                  :label="$t('labels.foundBy')"
                >
                </v-text-field>
                <v-datetime-picker
                  v-model="date_found"
                  :label="$t('labels.dateFound')"
                  :date-picker-props="dateProps"
                  time-format="HH:mm:ss"
                ></v-datetime-picker>
                <v-text-field v-model="venueDialog" :label="$t('labels.venue')">
                </v-text-field>
                <v-file-input
                  v-model="file"
                  :label="$t('labels.FilePicture')"
                  small-chips
                  counter
                  multiple
                  show-size
                  prepend-icon="mdi-image"
                  accept="image/*"
                ></v-file-input>

                <v-row class="mt-1 mb-2"
                    v-show="picAvail == 'none' ? false : true"
                >
                    <h6 class="blue-lcd">Gambar</h6>
                    <v-col 
                    cols="12" xs="12" sm="6" md="3"
                    v-for="(gambar, key) in images" :key="key"
                    >
                        <v-card class="border-12">
                            <v-card-text>
                                <v-img
                                    :lazy-src="gambar.src"
                                    max-height="300"
                                    max-width="300"
                                    :src="gambar.src"
                                ></v-img>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save">{{
              $t("buttons.save")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="closeDialog">{{
              $t("buttons.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-dialog>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          :multi-line="snackbar.mode === 'multi-line'"
          :timeout="snackbar.timeout"
          :top="snackbar.position === 'top'"
        >
          <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
              <div>
                <strong>{{ snackbar.title }}</strong>
              </div>
              <div>{{ snackbar.text }}</div>
            </v-layout>
          </v-layout>
          <v-btn
            v-if="snackbar.timeout === 0"
            icon
            @click="snackbar.visible = false"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false,
      },
      breadcumbs: [
        {
          text: "AYOLA",
          disabled: false,
          href: "/admin/ayala",
        },
        {
          text: "Report",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
        {
          text: "Lost & Found",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],
      statuses: ["Untaken", "Taken"],
      dialog: false,
      loading: false,
      loadingDialog: false,
      foundBy: "",
      venue: "",
      date_from: "",
      modal: false,
      date_to: "",
      modal_to: false,
      status: "",
      display: "non",
      headers: [
        {
          text: "No Doc",
          align: "start",
          sortable: false,
          value: "no_doc",
        },
        { text: "Found by", value: "found_by", sortable: false },
        { text: "Date found", value: "date_found", sortable: false },
        { text: "Venue", value: "venue", sortable: false },
        { text: "Taken by", value: "taken_by", sortable: false },
        { text: "Taken date", value: "taken_date", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      sortBy: "desc",
      sortName: "",
      totalItems: 10,
      options: {},
      data: [],
      pagination: 1,
      dateProps: {
        headerColor: "blue",
      },
      foundByDialog: "",
      venueDialog: "",
      date_found: new Date(),
      file: [],
      images:[],
      update:0,
      noDoc:'',
      picAvail:'none',
    };
  },
  mounted() {},
  methods: {
    resetVar() {
      this.foundByDialog = "";
      this.venueDialog = "";
      this.date_found = new Date();
      this.file = [];
      this.update= 0;
      this.picAvail = 'none';
    },
    search() {
      this.pagination = 1;
      this.searchText = true;
      this.getPullData(1, 10);
    },
    sortByFunc(sortBy) {
      this.sortBy = this.sortBy === "desc" ? "asc" : "desc";
      this.sortName = sortBy;
      this.getPullData(1, 10);
    },
    async getPullData(page = 1, itemsPerPage = 10) {
      this.loading = true;
      var start_date = this.date_from
        ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g, "")
        : "";
      var end_date = this.date_to
        ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g, "")
        : "";
      var stat = "";
      if (this.status == "Taken") {
        stat = "2";
      } else if (this.status == "Untaken") {
        stat = "1";
      } else {
        stat = "";
      }
      await axios
        .get(
          `${process.env.VUE_APP_URL}/api/ayola/lost_and_found?found_by=${
            this.foundBy
          }&status=${stat}&date_from=${start_date ? start_date : ""}&date_to=${
            end_date ? end_date : ""
          }&venue=${this.venue ? this.venue : ""}&sort=${
            this.sortBy ? this.sortBy : "desc"
          }&sortName=${this.sortName ? this.sortName : ""}&page=${
            page > 1 ? page : ""
          }&pagination=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
            },
          }
        )
        .then((res) => {
          this.display = "block";
          this.loading = false;
          this.data = res.data.data;
          this.totalItems = res.data.total;
          this.pagination = res.data.current_page;
        });
    },
    openDialog() {
      this.dialog = true;
    },
    async editItem(item) {
        console.log(item)
        this.openDialog()
        this.update = 1;
        this.noDoc = item.no_doc;
        //Todo Get data
        await axios
        .get(
          `${process.env.VUE_APP_URL}/api/ayola/lost_and_found?no_doc=${item.no_doc}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data[0])
          let data = res.data.data[0];
          this.foundByDialog = data.found_by
          console.log(this.foundByDialog)
          this.date_found = new Date(data.date_found)
          this.venueDialog = data.venue
          this.updateImages(item)
        });
    },
    closeDialog() {
      this.resetVar();
      this.dialog = false;
    },
    async updateImages(value){
        this.images = []
        await axios.get(`${process.env.VUE_APP_URL}/api/ayola/lost_and_found/images?no_doc=${value.no_doc}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            for (let index = 0; index < res.data.length; index++) {
              this.images.push({
                src: process.env.VUE_APP_URL + '/' + res.data[index].path + res.data[index].file_name
              })
            }

            if(this.images.length >0){
                this.picAvail = 'avail'
            }
        });
    },
    async save() {
      if(this.update == 0){
        this.saveData();
      } else {
        this.updateData();
      }
    },

    async saveData(){
      this.loadingDialog = true;
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(this.date_found - tzoffset).toISOString().slice(0, -1);
      let formData = new FormData();
      formData.append("office_id", "1");
      formData.append("found_by", this.foundByDialog);
      formData.append("date_found", localISOTime);
      formData.append("venue", this.venueDialog);
      formData.append("taken_by", this.taken_by ? this.taken_by : "");
      formData.append('shift_id', this.$store.state.user.shift);
      formData.append('outlet_id', this.$store.state.outlet.hkeep);
      for (var i = 0; i < this.file.length; i++) {
        let file = this.file[i];
        formData.append("file[" + i + "]", file);
      }
      await axios
        .post(`${process.env.VUE_APP_URL}/api/ayola/lost_and_found`, formData, 
        {
          headers: {
              Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
              "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
            console.log(res);
          this.loadingDialog = false;
          this.closeDialog();
          this.getPullData();

          this.snackbar = {
            color: "success",
            icon: "mdi-checkbox-marked-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Success",
            text: res.data.message,
            visible: true,
          };
        })
        .catch((err) => {
          this.loadingDialog = false;
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: err.response.data.message,
            visible: true,
          };
        });
    },

    async updateData(){
        this.loadingDialog = true;
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(this.date_found - tzoffset).toISOString().slice(0, -1);
      let formData = new FormData();
      formData.append("office_id", "1");
      formData.append("no_doc", this.noDoc);
      formData.append("found_by", this.foundByDialog);
      formData.append("date_found", localISOTime);
      formData.append("venue", this.venueDialog);
      formData.append("taken_by", this.taken_by ? this.taken_by : "");
      // formData.append('shift_id', this.$store.state.user.shift);
      // formData.append('outlet_id', this.$store.state.outlet.hkeep);
      for (var i = 0; i < this.file.length; i++) {
        let file = this.file[i];
        formData.append("file[" + i + "]", file);
      }
      await axios
        .post(`${process.env.VUE_APP_URL}/api/ayola/lost_and_found/update`, formData, 
        {
          headers: {
              Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
              "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loadingDialog = false;
          this.closeDialog();
          this.getPullData();

          this.snackbar = {
            color: "success",
            icon: "mdi-checkbox-marked-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Success",
            text: res.message,
            visible: true,
          };
        })
        .catch((err) => {
          this.loadingDialog = false;
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: err.response.data.message,
            visible: true,
          };
        });
    }
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.getPullData(page, itemsPerPage);
      },
      deep: true,
    },
  },
};
</script>
